import React, { useState, useEffect, useRef } from "react";
import "./upload.styles.css";
import { Container, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "../Loader/index";
import { validateImage, validateVideo } from "../../utils/utils";
import { addVideo } from "../../redux/video/video.action";
import { apiGateway } from "../../utils/config";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Button, CircularProgress } from "@mui/material";
import { GET_COMMON_DATA, UNIT_V1 } from "../../utils/constants";
import axios from "axios";
import useZustandStore from "../../zustandStore/useZustandStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

function Upload({
  toggleUpload,
  categoryId,
  creatorId,
  addSeriesVideos,
  setCreatorVideos,
}) {
  const videoRef = useRef();
  const axiosPrivate = useAxiosPrivate();
  const [title, setTitle] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [showVideoFrame, setShowVideoFrame] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [isExtracting, setIsExtracting] = useState(false);
  const [isFrameLoading, setIsFrameLoading] = useState(false);
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState({ error: "", type: "success" });
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.common);
  const isAdmin = user?.is_admin;
  const creatorCategory = useSelector(
    (state) => state.user.currentUser.category
  );
  const {
    addUpload,
    updateUploadProgress,
    removeUpload,
    cancelUpload,
    setOpenProgressBarPopUp,
    setShowAddVideosModal,
    setToastMessage,
  } = useZustandStore();

  const [video, setVideo] = useState(null);

  useEffect(() => {
    if (false && !isAdmin && !creatorCategory) {
      setErrorMsg({
        type: "failed",
        error: "Please Select a Category in Profile ",
      });
      history.push("/profile");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    commonData,
    commonData.creators,
    commonData.categories,
    commonData.activities,
  ]);

  const handleUpload = async (formData, uploadId) => {
    try {
      const { data: responseData, status } = await axiosPrivate.post(
        `${apiGateway}${GET_COMMON_DATA}`,
        formData
      );
      if (status === 200) {
        setShowAddVideosModal(false);
        const videoData = new FormData();
        videoData.append(
          "url",
          responseData.content_unit.upload_presigned_url.fields.key
        );
        videoData.append("video", video);
        videoData.append("slug", responseData.content_unit.slug);

        const cancelTokenSource = axios.CancelToken.source();
        addUpload({
          id: uploadId,
          title,
          progress: 0,
          cancelToken: cancelTokenSource,
          cancelled: false,
        });

        try {
          const request = axiosPrivate.post("/upload", videoData, {
            onUploadProgress: (progressEvent) => {
              const percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              updateUploadProgress(uploadId, percentage);
              setOpenProgressBarPopUp(true);
            },
            cancelToken: cancelTokenSource.token,
          });

          const response = await request;
          if (response.status === 200) {
            const { data: uploadFinishedData, status: uploadStatus } =
              await axiosPrivate.get(
                `${apiGateway}${UNIT_V1}${responseData.content_unit.slug}/upload-finished/`
              );
            if (uploadStatus === 200) {
              setShowLoader(false);
              setToastMessage({
                message: "Upload Finished!",
                type: "success",
              });
              dispatch(addVideo(uploadFinishedData));
              if (toggleUpload) {
                toggleUpload();
                setCreatorVideos([]);
                addSeriesVideos(1);
              }
            } else {
              setShowLoader(false);
              setToastMessage({
                message:
                  uploadFinishedData.error_message || "Something went wrong",
                type: "failed",
              });
            }
          } else {
            setShowLoader(false);
            setToastMessage({
              message: "Failed to upload",
              type: "failed",
            });
          }
        } catch (uploadError) {
          setShowLoader(false);
          setShowAddVideosModal(false);
          setToastMessage({
            message: uploadError?.message,
            type: "failed",
          });
        }
      } else {
        setShowLoader(false);
        setToastMessage({
          message: "Failed to add video unit",
          type: "failed",
        });
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setToastMessage({
          message: "Upload cancelled",
          type: "failed",
        });
      } else {
        setShowLoader(false);
        setToastMessage({
          message: "Error during Initial request",
          type: "failed",
        });
      }
    } finally {
      removeUpload(uploadId);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const isValidThumbnail = validateImage(thumbnail?.name);
      if (!isValidThumbnail) {
        setErrorMsg({
          type: "failed",
          error: "Please select a valid thumbnail of format png, jpeg, jpg",
        });
        return;
      }
      const isValidVideo = validateVideo(video?.name);
      if (!isValidVideo) {
        setErrorMsg({
          type: "failed",
          error: "Please select a valid video of format mp4",
        });
        return;
      }
      const b = video.size;
      const MB = (b * 8 * 1.192) / 10000000;
      const maxVideoSizeInMB = 800;
      if (MB > maxVideoSizeInMB) {
        setToastMessage({
          type: "failed",
          message: `Please Enter video of size less than ${maxVideoSizeInMB} MB`,
        });
        return;
      }
      const formData = new FormData();
      formData.append("title", title);
      formData.append("category_id", categoryId);
      formData.append("icon", thumbnail);
      formData.append("duration", videoDuration);
      formData.append("media_extension", "mp4");
      formData.append("creator_id", creatorId);
      setShowLoader(true);
      // Generating a unique ID for the upload
      const uploadId = title;
      handleUpload(formData, uploadId);
    } catch (error) {
      setShowLoader(false);
      setErrorMsg("Failed to upload");
    }
  };

  const onCaptureFrame = () => {
    try {
      const video = videoRef.current;
      const canvas = document?.createElement("canvas");
      const context = canvas.getContext("2d");
      if (video) {
        canvas.width = video.videoWidth ? video.videoWidth : 466;
        canvas.height = video.videoHeight ? video.videoWidth : 828;
        video.currentTime = video.currentTime; // Specify the desired time in seconds
        // Draw the current frame of the video onto the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
      }
      const frameDataUrl = canvas.toDataURL("image/jpeg");

      const parts = frameDataUrl.match(/data:([^;]+);base64,(.*)/);

      if (!parts || parts.length !== 3) {
        setErrorMsg({
          error: "Image cannot be generated invalid DataURl",
          type: "failed",
        });
        setIsExtracting(false);
      } else {
        const mimeType = parts[1];
        const base64Data = parts[2];
        const binaryData = atob(base64Data);
        const byteArray = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          byteArray[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([byteArray], { type: mimeType });
        const imageFile = new File([blob], "image.jpg", { type: mimeType });
        // Now you can use `imageFile` as needed
        if (imageFile) {
          setShowVideoFrame(false);
          setThumbnail(imageFile);
          setIsExtracting(false);
        } else {
          setErrorMsg({ error: "Image cannot be generated", type: "failed" });
          setIsExtracting(false);
        }
      }
      // Set up event listener for when video metadata is loaded
      video.onloadedmetadata = () => {
        const durationInSeconds = Math.floor(video.duration);
        setVideoDuration(durationInSeconds);
      };
    } catch (error) {
      setErrorMsg({ error: "Image cannot be generated", type: "failed" });
      setIsExtracting(false);
    }
    setIsFrameLoading(false);
  };

  const handleMetadataLoaded = () => {
    const t = 5;
    const timeInMilliSec = t * 1000;
    if (videoRef && videoRef.current) {
      videoRef.current.currentTime = timeInMilliSec / 1000;
    }
  };

  return (
    <div>
      <AlertComponent
        message={errorMsg?.error}
        type={errorMsg?.type}
        setAlertNotification={() => setErrorMsg({ error: "", type: "failed" })}
      />
      <div>
        {false ? (
          <Loader />
        ) : (
          <Container>
            <div>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter the title of video"
                    required
                    name="title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </Form.Group>
                <h6>Select Video to Upload</h6>
                <Form.Group
                  controlId="exampleForm.ControlInput1"
                  className="relative flex justify-center items-center border-dashed border-2 border-indigo-300 mt-2 h-[80px] p-2 rounded-lg"
                >
                  {/* <Form.Label>Select Video to Upload</Form.Label> */}
                  <Form.Control
                    style={{ height: "100%" }}
                    accept="video/mp4"
                    type="file"
                    required
                    name="video"
                    onChange={(e) => {
                      setVideo(e.target.files[0]);
                      setIsFrameLoading(true);
                      setTimeout(() => onCaptureFrame(), 1000);
                    }}
                    className="border absolute opacity-0"
                  />
                  <div className="text-center">
                    <FontAwesomeIcon
                      icon={faVideo}
                      className="w-fit mx-auto text-gray-500"
                    />
                    <h6 className="text-[12px] mt-1 line-clamp-1">
                      Upload Video {video?.name}
                    </h6>
                  </div>
                </Form.Group>
                {video && (
                  <Form.Group>
                    <Form.Label>Video Thumbnail</Form.Label>
                    <div className="left-button-flex">
                      <Form.Control
                        style={{ height: 30, width: 200 }}
                        type="file"
                        placeholder="Select Thumbnail"
                        variant="secondary"
                        name="thumbnail"
                        accept="image/*"
                        onChange={(e) => setThumbnail(e.target.files[0])}
                      />
                      <span className="ml-2 mr-2">Or</span>
                      <Button
                        className="btn-extract-thumbnail  cta-update-series-cancel"
                        onClick={() => setShowVideoFrame(true)}
                        variant="outlined"
                      >
                        Video frame
                      </Button>
                    </div>
                  </Form.Group>
                )}
                {video && (
                  <video
                    width="0"
                    ref={videoRef}
                    preload="metadata" // Load only video metadata
                    src={URL.createObjectURL(video)}
                    onLoadedMetadata={handleMetadataLoaded}
                  />
                )}
                <div className="center mb-3">
                  {isFrameLoading ? (
                    <CircularProgress />
                  ) : (
                    thumbnail && (
                      <img
                        src={URL.createObjectURL(thumbnail)}
                        width="100px"
                        height="130px"
                        style={{ border: "1px solid lightgrey" }}
                        alt="thumbnail"
                      />
                    )
                  )}
                </div>

                <div className="center">
                  <Button
                    className="cta-update-series"
                    type="submit"
                    variant="primary"
                    disabled={showLoader}
                    active
                  >
                    Upload
                  </Button>
                  <Button
                    className="ml-2 cta-update-series-cancel"
                    onClick={() => {
                      cancelUpload(title);
                      setShowAddVideosModal(false);
                    }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </Container>
        )}
      </div>
      <Modal show={showVideoFrame} centered>
        <Modal.Header closeButton onHide={() => setShowVideoFrame(false)}>
          <div className="modal-video-title">Extract Frame</div>
        </Modal.Header>
        <Modal.Body className="modal-body-frame-picker">
          <div className="modal-body-frame-center">
            {video && (
              <video width="300" controls ref={videoRef}>
                <source src={URL.createObjectURL(video)} />
              </video>
            )}
            <Button
              className="cta-update-series mt-2"
              onClick={() => onCaptureFrame()}
            >
              {isExtracting ? "Loading.." : "Extract Thumbnail"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Upload;

/* eslint-disable no-unused-vars */
import React from 'react'
import SplashScreen from '../../assests/images/Splashscreen_xxxhdpi.png';
import googlePlay from '../../assests/images/google_play.png';
import './login.styles.css';
import LoginForm from "../../components/Login-Form/login-form.component";

const Login = () => {
  return (
      <div>
        <div className='SW-wrapper-block'>
          <div className="SW-splash-screen-left">
            <img src={SplashScreen} className='SW-splash-block-image' alt=''/>
          </div>
          <div className="SW-splash-screen-form">
            <div className="SW-google-play">
              <a href="https://play.google.com/store/apps/details?id=com.seekho.android">
                <img src={googlePlay} className='SW-google-play-image' alt=''></img>
              </a>                   
            </div>
            <LoginForm />
          </div>
        </div>
      </div>
  )
}

export default Login
import React, { useEffect, useState } from "react";
import { CircularProgress, LinearProgress } from "@mui/material";
import { apiGateway } from "../../utils/config";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { errorState } from "../../utils/constants";
import Button from "react-bootstrap/Button";
import IconBreadcrumbs from "../BreadCrumbs/BreadCrumsCustom";
import AlertComponent from "../Alert-Messages/alert-component.component";
import QuillEditor from "../Common/QuillEditor";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CustomModal from "../Common/CustomModal";
import LoadingSpinner from "../Common/LoadingSpinner";

const EditBlog = ({ match }) => {
  const [editorHtml, setEditorHtml] = useState("");
  const [blogData, setBlogData] = useState(null);
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [isError, setIsError] = useState(errorState);
  const [isLoading, setIsLoading] = useState({
    getBlog: false,
    saveBlog: false,
    uploadImage: false,
  });
  const history = useHistory();
  const axiosPrivate = useAxiosPrivate();
  const blogSlug = match?.params?.blog_slug;

  const handleChange = (html) => {
    setEditorHtml(html);
  };

  const handleGetBlog = async () => {
    try {
      setIsLoading({ ...isLoading, getBlog: true });
      const url = `${apiGateway}/api/v1/blog/${blogSlug}/?request_source=cms`;
      const response = await axiosPrivate.get(url);
      if (response?.data) {
        setIsLoading({ ...isLoading, getBlog: false });
        setBlogData(response?.data?.blog);
        setEditorHtml(response?.data?.blog?.content);
      }
    } catch (error) {
      setIsLoading({ ...isLoading, getBlog: false });
    }
  };

  const handleSaveBlog = async () => {
    try {
      setIsLoading({ ...isLoading, saveBlog: true });
      const url = `${apiGateway}/api/v1/blog/${blogSlug}/update/`;
      const payload = new FormData();
      payload.append("content", editorHtml);
      const response = await axiosPrivate.post(url, payload);
      if (response?.data) {
        setOpenSaveConfirmation(false);
        setIsLoading({ ...isLoading, saveBlog: false });
        setIsError({ error: "Saved Successfully", type: "success" });
        history.push(`/blogs/${blogSlug}`);
      }
    } catch (error) {
      setIsLoading({ ...isLoading, saveBlog: false });
      setIsError({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  useEffect(() => {
    handleGetBlog();
  }, [blogSlug]);

  return (
    <div>
      <IconBreadcrumbs id="Edit blog" endPath={`Blogs`} link={`#/blogs`} />
      {isLoading.uploadImage && <LinearProgress color="inherit" />}

      <div className="h-[calc(100vh-10px)] overflow-y-auto !bg-white p-3">
        {isLoading.getBlog ? (
          <div className="h-screen flex justify-center items-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            <div className="flex gap-x-4 justify-between items-start">
              {/* // Blog Details */}
              <div className="flex gap-x-2 w-[85%]">
                {blogData?.image && (
                  <img
                    src={blogData?.image}
                    alt={blogData?.title}
                    className="w-[150px] h-[80px] object-contain rounded-md lg:h-[100px]"
                  />
                )}

                <div className="!w-[80%]">
                  <p className="font-bold line-clamp-1 capitalize">
                    {blogData?.title}
                  </p>
                  <p className="text-[14px] line-clamp-2">
                    {blogData?.description}
                  </p>
                </div>
              </div>

              {/* Save & Cancel buttons */}
              <Button
                onClick={() => setOpenSaveConfirmation(true)}
                disabled={isLoading.saveBlog || !editorHtml?.length}
                style={{ background: "#555190", borderColor: "#555190" }}
                className="rounded-md w-[100px] text-[13px]"
              >
                {isLoading.saveBlog ? (
                  <div className="h-[20px]">
                    <CircularProgress className="text-white" size={20} />
                    <p className="opacity-0">Save</p>
                  </div>
                ) : (
                  "Save"
                )}
              </Button>
              <Button
                onClick={() => history.push("/blogs")}
                className="bg-gray-600 rounded-md w-[100px] text-[13px] border-transparent hover:bg-gray-600 hover:border-transparent"
              >
                Cancel
              </Button>
            </div>

            {/* Editor */}
            <div className="lg:!w-[60%] mx-auto mt-1">
              <QuillEditor
                editorHtml={editorHtml}
                isLoading={isLoading}
                blogSlug={blogSlug}
                handleChange={handleChange}
                setIsLoading={setIsLoading}
                setIsError={setIsError}
              />
            </div>
          </div>
        )}
      </div>

      {/* Alert */}
      <AlertComponent
        message={isError?.error}
        type={isError?.type}
        setAlertNotification={() => setIsError({ error: "", type: "failed" })}
      />

      {/* Save confirmation */}
      <CustomModal
        show={openSaveConfirmation}
        title="Save Blog"
        handleConfirmationBtnText="Save"
        isLoading={isLoading?.saveBlog}
        onHide={() => setOpenSaveConfirmation(false)}
        handleConfirmation={handleSaveBlog}
      >
        Are you sure you want to save the blog?
      </CustomModal>
    </div>
  );
};

export default withRouter(EditBlog);

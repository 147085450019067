import React from "react";
import { faImages } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NoImageFound = ({ displayText }) => {
  return (
    <div>
      <FontAwesomeIcon
        icon={faImages}
        className="text-[28px] text-[#8277d4] text-center flex justify-center mx-auto"
      />
      <div className="text-[10px] mt-[2px]">{displayText}</div>
    </div>
  );
};

export default NoImageFound;

import React from "react";
import "./track.styles.css";
import TrackList from "../../components/Track/track-list.component";

function TrackPageList() {
    return (
        <div>
            <TrackList />
        </div>
    );
}

export default TrackPageList;

import React, { useEffect, useState } from "react";
import { apiGateway } from "../../utils/config";
import {
  CREATE_CATEGORY,
  EDIT_CATEGORY,
  GET_CATEGORY_LIST,
  TOGGLE_CATEGORY_STATUS,
} from "../../utils/constants";
import { LinearProgress, Pagination } from "@mui/material";
import { handleAppendPageToUrl } from "../../utils/utils";
import { useHistory, useLocation } from "react-router-dom";
import IconBreadcrumbs from "../BreadCrumbs/BreadCrumsCustom";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../Common/CustomModal";
import CategoryTable from "./CategoryTable";
import AddCategory from "./AddCategory";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import NoDataFound from "../Common/NoDataFound";
import useZustandStore from "../../zustandStore/useZustandStore";

const CategorySection = () => {
  const [isNoData, setIsNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditCategory, setIsEditCategory] = useState(false);
  const [createOrEditLoading, setCreateOrEditLoading] = useState(false);
  const [openCreateCategoryModal, setOpenCreateCategoryModal] = useState(false);
  const [isError, setIsError] = useState({ error: "", type: "success" });
  const [categoryData, setCategoryData] = useState(null);
  const [isPreviousTitle, setIsPreviousTitle] = useState("");
  const [categoryDetails, setCategoryDetails] = useState({
    title: "",
    metaTitle: "",
    description: "",
    image: null,
    slug: "", // only used while edit
    websiteCategoryImage: null,
  });
  const axiosPrivate = useAxiosPrivate();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const [pageNumber, setPageNumber] = useState(searchParamPage);
  const internalTeamAccessGlobalState = useZustandStore(
    (val) => val?.internalTeamAccessGlobalState
  );
  const hasAccessToCreate = internalTeamAccessGlobalState;

  // function which checks the missing fields while creating category
  const handleGetMissingFields = () => {
    const requiredFields = [
      { key: "title", value: "Title" },
      { key: "metaTitle", value: "Meta title" },
      { key: "image", value: "Image" },
      { key: "description", value: "Description" },
    ];
    return requiredFields.filter((field) => !categoryDetails?.[field.key]);
  };

  // GET List of Categories
  const handleGetCategoryList = async () => {
    try {
      setIsLoading(true);
      const url = `${apiGateway}${GET_CATEGORY_LIST}?page=${searchParamPage}`;
      const response = await axiosPrivate.get(url);
      if (response?.data) {
        setIsLoading(false);
        setCategoryData(response?.data);
        setIsNoData(response?.data?.length === 0 ? true : false);
      }
    } catch (error) {
      setIsNoData(true);
      setIsLoading(false);
      setIsError({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // function with API call to create a category
  const handleCreateCategoryAPICall = async () => {
    try {
      setCreateOrEditLoading(true);
      const formData = new FormData();
      formData.append("title", categoryDetails?.title);
      formData.append(
        "meta_title",
        `${categoryDetails?.title} | ${categoryDetails?.metaTitle}`
      );
      formData.append("meta_description", categoryDetails?.metaTitle);
      formData.append("category_icon_file", categoryDetails?.image);
      formData.append(
        "website_image_file",
        categoryDetails?.websiteCategoryImage
      );
      const url = `${apiGateway}${CREATE_CATEGORY}`;
      const response = await axiosPrivate.post(url, formData);
      if (response?.data) {
        setCreateOrEditLoading(false);
        setOpenCreateCategoryModal(false);
        handleGetCategoryList();
        setIsError({
          type: "success",
          error: response?.data?.message,
        });
        setCategoryDetails({
          title: "",
          metaTitle: "",
          description: "",
          image: "",
          websiteCategoryImage: null,
          image: null,
        });
      }
    } catch (error) {
      setCreateOrEditLoading(false);
      setIsError({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Function to validates and make an api call when click on create category
  const handleCreateCategory = () => {
    const missingFields = handleGetMissingFields();
    if (missingFields.length > 0) {
      setIsError({
        error: `${missingFields?.map((item) => {
          return ` ${item?.value}`;
        })} fields are required`,
        type: "error",
      });
    } else {
      handleCreateCategoryAPICall();
    }
  };

  // API call when to edit category
  const handleEditCategoryAPICall = async () => {
    const missingFields = handleGetMissingFields();
    if (missingFields.length > 0) {
      setIsError({
        error: `${missingFields?.map((item) => {
          return ` ${item?.value}`;
        })} fields are required`,
        type: "error",
      });
    } else {
      try {
        setCreateOrEditLoading(true);
        const formatTitle = categoryDetails?.title?.split("|")[0] + " ";
        const formData = new FormData();
        // To prevent same title of the category
        if (isPreviousTitle !== categoryDetails?.title) {
          formData.append("title", categoryDetails?.title);
        }
        // this sets the meta_title, makes sure the previous is not undefined and then sets
        formData.append(
          "meta_title",
          `${formatTitle}|${
            categoryDetails?.metaTitle?.split("|")[1] !== "undefined"
              ? categoryDetails?.metaTitle?.split("|")?.pop()
              : ""
          }`
        );
        formData.append("meta_description", categoryDetails?.description);
        formData.append("category_icon_file", categoryDetails?.image);
        formData.append(
          "website_image_file",
          categoryDetails?.websiteCategoryImage
        );
        const url = `${apiGateway}${EDIT_CATEGORY}${categoryDetails?.slug}/update/`;
        const response = await axiosPrivate.post(url, formData);
        if (response?.data) {
          handleGetCategoryList();
          setOpenCreateCategoryModal(false);
          setCreateOrEditLoading(false);
          setIsEditCategory(false);
          setIsError({
            type: "success",
            error: response?.data?.message,
          });
        }
      } catch (error) {
        setCreateOrEditLoading(false);
        setIsError({
          type: "error",
          error: error?.response?.data?.error_message || error?.message,
        });
      }
    }
  };

  // Function prefills the state when clicked on edit in the table
  const handleEditCategory = ({ item }) => {
    setIsEditCategory(true);
    setIsPreviousTitle(item?.title);
    setOpenCreateCategoryModal(true);
    setCategoryDetails({
      title: item?.title,
      metaTitle: item?.meta_title || "",
      image: item?.home_icon,
      description: item?.meta_description || "",
      slug: item?.slug,
      websiteCategoryImage: item?.website_image,
    });
  };

  // Function to toggle the active status
  const handleToggleActive = async ({ categorySlug, status }) => {
    try {
      const url = `${apiGateway}${TOGGLE_CATEGORY_STATUS}${categorySlug}/toggle-active/`;
      const formData = new FormData();
      formData.append("is_active", status ? false : true);
      const response = await axiosPrivate.post(url, formData);
      if (response?.data) {
        setIsError({
          type: "success",
          error: response?.data?.message,
        });
        handleGetCategoryList();
      }
    } catch (error) {
      setIsError({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Function to toggle the default status
  const handleToggleDefault = async ({ categorySlug, status }) => {
    try {
      const url = `${apiGateway}${TOGGLE_CATEGORY_STATUS}${categorySlug}/toggle-default/`;
      const formData = new FormData();
      formData.append("is_default", status ? false : true);
      const response = await axiosPrivate.post(url, formData);
      if (response?.data) {
        setIsError({
          type: "success",
          error: response?.data?.message,
        });
        handleGetCategoryList();
      }
    } catch (error) {
      setIsError({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  const handleOpenCreateCategoryModal = () => {
    setOpenCreateCategoryModal(true);
    setCategoryDetails({
      title: "",
      metaTitle: "",
      description: "",
      image: "",
      websiteCategoryImage: null,
    });
  };

  // Modal close function
  const handleCloseModal = () => {
    setOpenCreateCategoryModal(false);
    setIsEditCategory(false);
    setCategoryDetails({
      title: "",
      metaTitle: "",
      description: "",
      image: "",
      websiteCategoryImage: null,
    });
  };

  useEffect(() => {
    handleGetCategoryList();
  }, [pageNumber]);

  return (
    <div>
      <IconBreadcrumbs endPath={"Category"} />
      <AlertComponent
        message={isError?.error}
        type={isError?.type}
        setAlertNotification={() => setIsError({ error: "", type: "failed" })}
      />
      {hasAccessToCreate && (
        <div
          className="cta-button-create"
          onClick={handleOpenCreateCategoryModal}
        >
          <AddIcon fontSize="large" className="icon-plus-cta" />
          <span>Create Category</span>
        </div>
      )}

      <div className="mt-3">
        {isLoading && <LinearProgress color="inherit" />}

        {isNoData ? (
          <div className="!flex !justify-center !items-center h-[calc(100vh-160px)]">
            <NoDataFound
              className="!w-[450px] !mx-auto"
              displayText="Ooops!! Looks like something went wrong!!"
            />
          </div>
        ) : (
          <div>
            <CategoryTable
              data={categoryData?.category_list}
              handleEditCategory={handleEditCategory}
              handleToggleDefault={handleToggleDefault}
              handleToggleActive={handleToggleActive}
            />

            <Pagination
              count={categoryData?.n_pages}
              page={searchParamPage}
              onChange={(e, val) => {
                setPageNumber(val);
                handleAppendPageToUrl({ history, pageNumber: val });
              }}
              variant="outlined"
              shape="rounded"
              className="!w-fit !mx-auto mt-3"
            />
          </div>
        )}
      </div>

      <CustomModal
        show={openCreateCategoryModal}
        onHide={handleCloseModal}
        title={`${isEditCategory ? "Edit" : "Create"} Category`}
        isLoading={createOrEditLoading}
        handleConfirmationBtnText={
          isEditCategory ? "Update Category" : "Create Category"
        }
        handleConfirmation={() => {
          if (isEditCategory) {
            handleEditCategoryAPICall();
          } else {
            handleCreateCategory();
          }
        }}
      >
        <AddCategory
          categoryDetails={categoryDetails}
          setCategoryDetails={setCategoryDetails}
        />
      </CustomModal>
    </div>
  );
};

export default CategorySection;

import React from "react";
import "./series.styles.css";
import SeriesList from "../../components/SeriesList/series-list.component"

export default function SeriesPage() {
  return <div style={{overflowY: "hidden"}}>
      <SeriesList> 
      </SeriesList>
  </div>;
}

/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import "./create-series.styles.css";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createSeries } from "../../redux/series/series.action";
import ImageEditor from "../ImageEditor/image-editor.component";
import {
  faCamera,
  faImage,
  faTrashAlt,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { Chip, Paper, styled } from "@mui/material";
import { apiGateway } from "../../utils/config";
import { handleModifyFirstLetterToCapital } from "../../utils/utils";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  CATEGORY_CMS_ALL,
  CATEGORY_V1,
  CREATE_SERIES,
  GET_CREATOR_LIST,
  GET_SERIES_SIMILAR_TITLES,
  GET_SHOWS_LIST_V1,
  UNIT_V1,
} from "../../utils/constants";
import useZustandStore from "../../zustandStore/useZustandStore";
import axios from "axios";
import RequiredStar from "../Common/RequiredStar";

function CreateSeries({ toggleCreate, handleFetchSeriesAfterCreated }) {
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [searchInput, setSearchInput] = useState("");
  const [seriesTitles, setSeriesTitles] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [showSeriesTitle, setShowSeriesTitle] = useState(false);
  const [title, setTitle] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [titleIcon, setTitleIcon] = useState(null);
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [isNews, setIsNews] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ error: "", type: "success" });
  const [creatorColor, setCreatorColor] = useState("#fff000");
  const [showLoader, setShowLoader] = useState(false);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [creatorId, setCreatorId] = useState(null);
  const [creators, setCreators] = useState([]);
  const [creator, setCreator] = useState(null);
  const [tagsMap] = useState(new Map());
  const [primaryChips, setPrimaryChips] = useState([]);
  const [primaryChips1, setPrimaryChips1] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showId, setShowId] = useState("");
  const [showsList, setShowsList] = useState([]);
  const [addTitleImage, setAddTitleImage] = useState(false);
  const titleIconRef = useRef();
  const [listIsLoading, setListIsLoading] = useState(false);
  const [videoData, setVideoData] = useState({
    videoFile: null,
    videoDuration: null,
    videoThumbnail: null,
  });
  // common functions to add, update & delete the video in upload pop up
  const {
    addUpload,
    updateUploadProgress,
    removeUpload,
    setOpenProgressBarPopUp,
    setToastMessage,
    setOpenCreateSeriesModal,
  } = useZustandStore();

  const history = useHistory();
  const commonData = useSelector((state) => state.common);
  const internalTeamAccessGlobalState = useZustandStore(
    (val) => val?.internalTeamAccessGlobalState
  );

  const creatorCategory = useSelector(
    (state) => state.user.currentUser.category
  );

  const getShowsList = () => {
    setListIsLoading(true);
    const url = `${apiGateway}${GET_SHOWS_LIST_V1}/?page=1&page_size=1000000`;
    axiosPrivate
      .get(url)
      .then(({ data, status }) => {
        if (status === 200) {
          setShowsList(data?.show_list);
          setListIsLoading(false);
        }
      })
      .catch(({ response }) => {
        setErrorMsg({
          type: "failed",
          error: response?.data?.message ?? "Something went wrong",
        });
        setListIsLoading(false);
      });
  };

  // Function to extract thumbnail from video's 1st frame
  const extractThumbnailFromVideo = async (videoFile) => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";
      videoElement.onloadedmetadata = () => {
        videoElement.currentTime = 10;
      };
      videoElement.onseeked = async () => {
        const canvas = document.createElement("canvas");
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoElement, 0, 0, canvas?.width, canvas?.height);
        const thumbnailDataURL = canvas.toDataURL("image/jpeg");
        const thumbnailBlob = await fetch(thumbnailDataURL).then((res) =>
          res.blob()
        );
        resolve(thumbnailBlob);
      };
      videoElement.onerror = (error) => {
        reject(new Error("Error loading video: " + error));
      };
      videoElement.src = URL.createObjectURL(videoFile);
    });
  };

  const handleVideoOnChange = async (e) => {
    const file = e.target?.files[0];
    if (file) {
      const videoURL = URL.createObjectURL(file);
      const videoElement = document.createElement("video");
      videoElement.src = videoURL;
      videoElement.onloadedmetadata = () => {
        const duration = Math.floor(videoElement?.duration);
        setVideoData((prevState) => ({
          ...prevState,
          videoFile: file,
          videoDuration: duration,
        }));
        URL.revokeObjectURL(videoURL); // Clean up the URL
      };
      // Extract thumbnail
      const thumbnailBlob = await extractThumbnailFromVideo(file);
      const thumbnailFile = new File([thumbnailBlob], "thumbnail.jpg", {
        type: "image/jpeg",
      });
      setVideoData((prevState) => ({
        ...prevState,
        videoThumbnail: thumbnailFile,
      }));
    }
  };

  const handleUploadFinishedAPI = async ({ slug }) => {
    try {
      const url = `${apiGateway}${UNIT_V1}${slug}/upload-finished/`;
      const response = await axiosPrivate.get(url);
      if (response?.data) {
        handleFetchSeriesAfterCreated();
        setToastMessage({ type: "success", message: "Uploaded Successfully" });
      }
    } catch (error) {
      setToastMessage({
        message: error?.response?.data?.error_message || error?.message,
        type: "failed",
      });
    }
  };

  const handleUploadFailedAPI = async ({ slug }) => {
    try {
      const url = `${apiGateway}${UNIT_V1}${slug}/upload-failed/`;
      await axiosPrivate.get(url);
    } catch (error) {
      setToastMessage({
        message: error?.response?.data?.error_message || error?.message,
        type: "failed",
      });
    }
  };

  const handleUploadVideo = async ({ formData, uploadId }) => {
    try {
      const url = `${apiGateway}${CREATE_SERIES}`;
      // uploading the video and other data to BE
      const apiResponse = await axiosPrivate.post(url, formData);
      if (apiResponse?.status === 200) {
        setOpenCreateSeriesModal(false);
        const apiResponseData = apiResponse?.data;
        dispatch(createSeries(apiResponseData)); // Adding the created series
        // signedUrl key for final video upload
        const key =
          apiResponseData?.content_unit?.upload_presigned_url?.fields?.key;
        const slug = apiResponseData?.content_unit?.slug;
        // payload for video upload
        const videoFormData = new FormData();
        videoFormData.append("url", key);
        videoFormData.append("video", videoData?.videoFile);
        videoFormData.append("slug", slug);
        const cancelTokenSource = axios.CancelToken.source(); // cancel token
        // common function which allows async video uploads, adds the current upload in it
        addUpload({
          id: uploadId,
          title,
          progress: 0,
          cancelToken: cancelTokenSource,
          cancelled: false,
        });
        try {
          // upload request
          const request = await axiosPrivate.post("/upload", videoFormData, {
            onUploadProgress: (progressEvent) => {
              const percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              updateUploadProgress(uploadId, percentage); // updates the progress bar in pop up
              setOpenProgressBarPopUp(true); // Pop's up the video upload progress
            },
            cancelToken: cancelTokenSource.token,
          });
          const uploadResponse = await request; // uploaded response
          if (uploadResponse?.data) {
            handleUploadFinishedAPI({ slug }); // to check if video is uploaded
          }
        } catch (error) {
          handleUploadFailedAPI({ slug }); // video upload failed API call
          setToastMessage({ type: "failed", message: error?.message });
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setToastMessage({
          message: "Upload cancelled by user",
          type: "failed",
        });
      } else {
        setShowLoader(false);
        setToastMessage({
          message: error?.response?.data?.error_message || error?.message,
          type: "failed",
        });
      }
    } finally {
      removeUpload(uploadId);
    }
  };

  const handleMissingFields = ({
    title,
    category,
    videoData,
    primaryTagIds,
  }) => {
    const missingFields = !title
      ? "Title"
      : videoData?.videoFile === null
      ? "Video"
      : !category
      ? "Category"
      : !showId.length
      ? "Show"
      : !primaryTagIds?.length
      ? "Tags"
      : null;
    return missingFields;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const primaryTagIds = primaryChips?.map((chip) =>
      tagsMap.get(chip)?.toString()
    );
    const tagIdsString = JSON.stringify(primaryTagIds);
    if (internalTeamAccessGlobalState && !creator) {
      return setErrorMsg({
        type: "failed",
        error: "Please Select Creator",
      });
    }
    // normal validation
    const isValid =
      !title ||
      !category ||
      !primaryTagIds?.length ||
      videoData?.videoFile === null ||
      !showId;
    if (isValid) {
      setErrorMsg({
        type: "failed",
        error: `${handleMissingFields({
          title,
          category,
          primaryTagIds,
          videoData,
        })} field is required`,
      });
    } else {
      // payload in form of FormData
      const formData = new FormData();
      formData.append(
        "title",
        handleModifyFirstLetterToCapital({ sentence: title })
      );
      formData.append("description", description);
      formData.append("category_id", category);
      formData.append("add_title_in_image", addTitleImage);
      formData.append("is_news", isNews);
      formData.append("video_thumbnail_file", videoData?.videoThumbnail);
      formData.append("video_duration", videoData?.videoDuration);
      formData.append("video_extension", "mp4");
      formData.append("tag_ids", tagIdsString);
      if (internalTeamAccessGlobalState)
        formData.append("creator_id", creator?.id);
      if (showId?.length > 0) formData.append("show_id", showId);
      if (thumbnail) formData.append("thumbnail_image", thumbnail);
      if (titleIcon) formData.append("title_icon", titleIcon);
      setShowLoader(true);
      try {
        const uploadId = title; // unique uploadId for every upload
        handleUploadVideo({ formData: formData, uploadId }); // Function breakdown for code readability
      } catch (error) {
        setErrorMsg(
          error.response?.data
            ? error.response.data.error_message
            : "Something went wrong"
        );
      } finally {
        setShowLoader(false);
      }
    }
  };

  const handleSearchCreator = async (e) => {
    const searchName = e.target.value;
    setSearchInput(searchName);
    if (searchName.length >= 3) {
      const url = `${apiGateway}${GET_CREATOR_LIST}?page=${1}&q=${searchName}`;
      axiosPrivate
        .get(url)
        .then(({ data, status }) => {
          if (Object.keys(data).length !== 0 && status === 200)
            setCreators(data.users);
        })
        .catch(({ response }) => {
          setErrorMsg({
            type: "failed",
            error: response?.data
              ? response?.data?.error_message
              : "Failed to fetch creator",
          });
        });
    } else {
      setCreators([]);
      setCreator(null);
    }
  };

  const handleSelectCreator = (creator) => {
    setCreator(creator);
    setSearchInput(creator.name);
  };

  const handleFetchSuggestions = async (data, cb, categorySlug, categoryId) => {
    // setPrimaryChips1([]);
    if (commonData?.categories?.length) {
      if (!categorySlug) {
        for (let i = 0; i < commonData?.categories.length; i++) {
          if (categoryId) {
            if (+categoryId === +commonData?.categories[i].id) {
              categorySlug = commonData?.categories[i].slug;
              break;
            }
          } else if (+category === +commonData?.categories[i].id) {
            categorySlug = commonData?.categories[i].slug;
            break;
          }
        }
      }
      if (categorySlug) {
        const url = `${apiGateway}${CATEGORY_V1}${categorySlug}/tags/`;
        axiosPrivate
          .get(url)
          .then(async ({ data, status }) => {
            if (status === 200) {
              const titles = data?.tags?.map((tag) => {
                tagsMap.set(tag?.title, tag?.id);
                setPrimaryChips1((prev) => [
                  ...prev,
                  { title: tag?.title, id: tag?.id },
                ]);
                // setSecondaryChips1((prev) => [
                //   ...prev,
                //   { title: tag?.title, id: tag?.id },
                // ]);
                return tag?.title;
              });
              cb && (await cb(titles));
            }
          })
          .catch(({ response }) => {
            setErrorMsg({
              type: "failed",
              error: response?.data
                ? response?.data?.error_message
                : "Something went wrong",
            });
          });
      }
    }
  };

  const fetchSeriesTitles = async () => {
    const url = `${apiGateway}${GET_SERIES_SIMILAR_TITLES}/?q=${title}`;
    axiosPrivate
      .get(url)
      .then(async ({ data, status }) => {
        if (status === 200) {
          setSeriesTitles(data.series_titles);
          setShowMore(false);
        }
      })
      .catch(({ response }) => {
        setErrorMsg({
          type: "failed",
          error: response?.data
            ? response?.data?.error_message
            : "Something went wrong",
        });
      });
  };

  useEffect(() => {
    if (false && !internalTeamAccessGlobalState && !creatorCategory) {
      setErrorMsg({
        type: "failed",
        error: "Please Select a Category in Profile ",
      });
      history.push("/profile");
    } else {
      setCategory(commonData?.categories[0]?.id);
      setCreatorId(commonData?.creators[0]?.id);
      handleFetchSuggestions(null, null, commonData?.categories[0]?.slug);
    }
  }, []);

  useEffect(() => {
    let url = `${apiGateway}${CATEGORY_CMS_ALL}?page=1&default_only=true`;
    axiosPrivate
      .get(url)
      .then(({ data, status }) => {
        if (status === 200) {
          setCategories([...data.categories]);
          setCategory(data.categories[0].id);
          handleFetchSuggestions(null, null, data.categories[0]?.slug);
        }
      })
      .catch(({ response }) => {
        setErrorMsg({
          type: "failed",
          error: response?.data
            ? response?.data?.error_message
            : "Category failed to fetch",
        });
      });
    getShowsList();
  }, []);

  return (
    <div>
      <div>
        <AlertComponent
          message={errorMsg?.error}
          type={errorMsg?.type}
          setAlertNotification={() =>
            setErrorMsg({ error: "", type: "failed" })
          }
        />
        <Container className="create-series">
          <div>
            <Form onSubmit={handleSubmit}>
              {/* Language and quality */}
              {/* <div className="d-flex flex-1">
                <Form.Group
                    className="flex-1 w-50 mr-2"
                    controlId="exampleForm.ControlInput1"
                    // onClick={() => setShowSeriesTitle(true)}
                >
                  <Form.Label>Language</Form.Label>
                  <Form.Control
                      as="select"
                      name="language"
                      onChange={(e) => {
                        setLang(e.target.value)
                      }}
                      value={lang}
                    >
                      {language?.map((it) => (
                        <option value={it.code} key={it.code}>{it?.language}</option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group
                  className="flex-1 w-50"
                  controlId="exampleForm.ControlInput1"
                    // onClick={() => setShowSeriesTitle(true)}
                >
                  <Form.Label>Series Quality</Form.Label>
                  <Form.Control
                      as="select"
                      name="quality"
                      onChange={(e) => {
                        setSeriesQuality(e.target.value)
                      }}
                      value={seriesQual}
                    >
                      {SeriesQuality?.map((it) => (
                        <option value={it.value} key={it.value}>{it?.label}</option>
                      ))}
                  </Form.Control>
              </Form.Group>
              </div> */}

              {/* Video title */}
              <Form.Group
                controlId="exampleForm.ControlInput1"
                onClick={() => setShowSeriesTitle(true)}
              >
                <Form.Label>
                  Video Title <RequiredStar />
                </Form.Label>
                <div className="series-title-input">
                  <div className="series-title-dropdown">
                    <Form.Control
                      type="text"
                      placeholder="Enter the title of series"
                      required
                      name="title"
                      value={title}
                      onChange={(e) => {
                        setSeriesTitles([]);
                        setTitle(e.target.value);
                      }}
                    />
                    {showSeriesTitle && seriesTitles.length > 0 && (
                      <div className="dropdown">
                        {seriesTitles
                          ?.map((title, i) => <li key={i}>{title}</li>)
                          .slice(0, showMore ? seriesTitles.length : 2)}
                        <div
                          className="show-more"
                          onClick={() => setShowMore((prev) => !prev)}
                        >
                          {" "}
                          {showMore ? (
                            <span>show less</span>
                          ) : (
                            <span>show more</span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <span className="verify" onClick={fetchSeriesTitles}>
                    verify
                  </span>
                </div>
              </Form.Group>

              {/* Video Upload */}
              <p>
                Video <RequiredStar />
              </p>
              <div className="border-dashed border-2 border-indigo-300 text-center h-[85px] flex items-center justify-center relative mb-3 rounded-lg mt-1">
                <input
                  type="file"
                  accept=".mp4"
                  className="border h-[100%] absolute right-0 left-0 opacity-0"
                  onChange={handleVideoOnChange}
                />
                <div>
                  <FontAwesomeIcon icon={faVideo} className="text-gray-600" />
                  <h6 className="text-[12px] line-clamp-1">
                    {videoData?.videoFile?.name
                      ? videoData?.videoFile?.name
                      : "Upload Video"}
                  </h6>
                </div>
              </div>

              {/* Select Creator only for admin */}
              <div onClick={() => setShowSeriesTitle(false)}>
                {internalTeamAccessGlobalState && (
                  <Form.Group>
                    <Form.Label>Creator</Form.Label>
                    <div className="search-select">
                      <div className="dropdown">
                        <input
                          autoComplete="off"
                          value={searchInput}
                          name="searchInput"
                          placeholder="Enter Creator Name"
                          onChange={handleSearchCreator}
                        ></input>
                        <div className="dropdown-content">
                          {creators?.map((creator, i) => {
                            return (
                              <div
                                key={i}
                                onClick={() => handleSelectCreator(creator)}
                              >
                                {creator.name}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                )}

                {/* Category */}
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>
                    Category <RequiredStar />
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="category"
                    onChange={(e) => {
                      setPrimaryChips([]);
                      // setSecondaryChips([]);
                      setPrimaryChips1([]);
                      // setSecondaryChips1([]);
                      setCategory(e.target.value);
                      handleFetchSuggestions(null, null, null, e.target.value);
                    }}
                  >
                    {categories?.map((category) => (
                      <option value={category.id} key={category.id}>
                        {category?.title}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {/* Show Name */}
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>
                    Show Name <RequiredStar />
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="show"
                    onChange={(e) => {
                      setShowId(e.target.value);
                    }}
                  >
                    <option value={""}>
                      {listIsLoading ? "Loading..." : "None"}
                    </option>
                    {showsList?.map((show, index) => (
                      <option key={`${show.id}-${index}`} value={show.id}>
                        {show.title}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {/* Tags */}
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>
                    Tags <RequiredStar />
                  </Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Add Tags"
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val) setPrimaryChips((prev) => [...prev, val]);
                    }}
                  >
                    <option value={null}></option>
                    {primaryChips1?.map(
                      (tag, i) =>
                        primaryChips.indexOf(tag?.title) < 0 && (
                          <option value={tag?.title} key={i}>
                            {tag?.title}
                          </option>
                        )
                    )}
                  </Form.Control>

                  {primaryChips.length !== 0 && (
                    <Paper
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        listStyle: "none",
                        p: 0.5,
                        m: 0,
                      }}
                      component="ul"
                    >
                      {primaryChips?.map((data, i) => {
                        let icon;
                        return (
                          <ListItem key={data}>
                            <Chip
                              icon={icon}
                              label={data}
                              onDelete={() =>
                                setPrimaryChips((prev) =>
                                  prev.filter((d) => d !== data)
                                )
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </Paper>
                  )}
                </Form.Group>

                {/* Thumbnail and title icon */}
                <Form.Group
                  className="cta"
                  controlId="exampleForm.ControlInput1"
                >
                  <div>
                    <Button
                      variant="secondary"
                      active
                      onClick={(e) => {
                        e.preventDefault();
                        setShowImageEditor(true);
                      }}
                      className="!min-h-[85px] text-[12px] bg-transparent !text-black !border-dashed !border-2 !border-indigo-300 rounded-lg"
                    >
                      <FontAwesomeIcon icon={faCamera} /> <br />
                      Upload Thumbnail
                    </Button>
                    {thumbnail && (
                      <div className="image-preview">
                        <img src={URL.createObjectURL(thumbnail)} />
                        <FontAwesomeIcon
                          className="icon"
                          icon={faTrashAlt}
                          onClick={() => setThumbnail(null)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="title-icon">
                    <Button
                      variant="success"
                      active
                      onClick={(e) => {
                        titleIconRef.current.click();
                      }}
                      className="!min-h-[85px] text-[12px] bg-transparent !text-black !border-dashed !border-2 !border-indigo-300 rounded-lg"
                    >
                      <FontAwesomeIcon icon={faImage} /> <br />
                      Select Title Icon
                    </Button>
                    <Form.Control
                      type="file"
                      name="titleIcon"
                      ref={titleIconRef}
                      onChange={(e) => setTitleIcon(e.target.files[0])}
                    />{" "}
                    {titleIcon && (
                      <div className="image-preview">
                        <img src={URL.createObjectURL(titleIcon)} />
                        <FontAwesomeIcon
                          className="icon"
                          icon={faTrashAlt}
                          onClick={() => {
                            setTitleIcon(null);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Form.Group>

                {/* Description*/}
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter the description of series"
                    name="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>

                {/* is News*/}
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Check
                    name="isNews"
                    onChange={(e) => {
                      setIsNews(e.target.checked);
                    }}
                    label="Is news?"
                  />
                </Form.Group>
              </div>
              <div>
                <Button
                  type="submit"
                  disabled={showLoader}
                  variant="outline-primary"
                  active
                  className="cta-update-series"
                >
                  {!showLoader ? "Create" : "Saving...."}
                </Button>
              </div>
            </Form>
          </div>
        </Container>

        <Modal className="image-editor-modal" show={showImageEditor}>
          <Modal.Header closeButton onHide={() => setShowImageEditor(false)}>
            <div className="modal-video-title">Select Thumbnail</div>
          </Modal.Header>
          <Modal.Body>
            <ImageEditor
              done={(file) => setThumbnail(file)}
              close={() => setShowImageEditor(false)}
              image={thumbnail && URL.createObjectURL(thumbnail)}
              creatorColor={creatorColor}
              setCreatorColor={(creatorColor) => setCreatorColor(creatorColor)}
              creatorName={creator?.name}
              seriesTitle={title}
              addTitleImage={addTitleImage}
              setAddTitleImage={setAddTitleImage}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default CreateSeries;

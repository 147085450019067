import { createSelector } from "reselect";

const selectSeries = (state) => state.series;

export const selectAllSeries = createSelector(
  [selectSeries],
  (series) => series.series
);

export const selectCurrentPageNumber = createSelector(
  [selectSeries],
  (series) => series.currentPageNumber
);

export const selectIsSeriesFetching = createSelector(
  [selectSeries],
  (series) => series.isFetching
);

export const selectHasMore = createSelector(
  [selectSeries],
  (series) => series.hasMore
);

export const selectFilters = createSelector(
  [selectSeries],
  (series) => series.filters
);



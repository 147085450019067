import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { logout, setCurrentUser } from "../../redux/user/user.action";
import "./side-navigation.styles.css";
import { handleDefaultProfile } from "../../utils/utils";
import { handleDisplayRole } from "../../utils/constants/Team";
import { SIDE_NAVIGATION } from "../../utils/constants";
import image from "../../assests/images/Logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import CustomModal from "../Common/CustomModal";

const SideNavigation = (props) => {
  const [showLogout, setShowLogout] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentActive, setCurrentActive] = useState(
    window.location.hash ?? "#/shows"
  );
  const currentUser = props?.currentUser;

  // just to have a safe side, when roles key is not defined from backend
  const localRoles = [];
  if (currentUser?.is_admin) {
    localRoles.push("admin");
  }

  if (currentUser?.is_quality_approved) {
    localRoles.push("creator");
  }

  // if roles key is missed from backed, alternate localRoles will handle it, to avoid any break down
  const roles = currentUser?.roles || localRoles;

  // Filter navigation based on user roles
  const getFilteredNavigation = (roles) => {
    let filteredNavigation = SIDE_NAVIGATION.filter((item) =>
      item.requiredRoles.some((role) => roles?.includes(role))
    );

    // If the user has only the 'creator' role, 'Series' comes first
    if (roles?.length === 1 && roles?.includes("creator")) {
      filteredNavigation = filteredNavigation.sort((a, b) => {
        if (a.title === "Series") return -1;
        if (b.title === "Series") return 1;
        return 0;
      });
    }
    return filteredNavigation;
  };

  const filteredNavigation = getFilteredNavigation(roles);

  useEffect(() => {
    const handleResize = () => {
      setCurrentActive(window.location.hash);
    };
    window.addEventListener("hashchange", handleResize);
    return () => window.removeEventListener("hashchange", handleResize);
  }, [window.location]);

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar">
        <header className="dashboard-header">
          <img src={image} alt="" style={{ marginLeft: 10, marginTop: 30 }} />
        </header>
        <div className="sidenavbar-layout-wrapper mt-4">
          <ul className="sidenavbar-layout-listnav" onClick={props?.onClose}>
            {filteredNavigation?.map((item) => {
              return (
                <div key={item.id}>
                  <li onClick={() => setCurrentActive(`#${item.navTo}`)}>
                    <Link
                      className={`nav-link-custom ${
                        currentActive.startsWith(`#${item.navTo}`)
                          ? "active-link"
                          : ""
                      }`}
                      to={item.navTo}
                    >
                      <div>{item.icon}</div>
                      <span>{item.title}</span>
                    </Link>
                  </li>
                </div>
              );
            })}
          </ul>
          <div>
            <div className="sidenavbar-layout-bottom">
              <div className="SW-wrapper-button-profile-group">
                <div
                  onClick={(e) => {
                    window.location.href = "#/profile";
                    if (props?.onClose) {
                      props?.onClose(e);
                    }
                  }}
                  className="button-profile-group"
                >
                  <div>
                    <img
                      src={
                        currentUser?.avatar ||
                        handleDefaultProfile({
                          userName: currentUser?.name,
                        })
                      }
                      width={50}
                      height={50}
                      alt={currentUser?.name}
                      className="img-avatar-bottom-navbar"
                    />
                  </div>
                  <div>
                    <p className="ptag-name-sidebar !line-clamp-1">
                      {currentUser?.name || "Seekho User"}
                    </p>

                    <div className="flex gap-x-1 line-clamp-1 text-[10px]">
                      {currentUser?.roles
                        ?.map((item) => handleDisplayRole({ role: item }))
                        .filter(Boolean)
                        .join(", ")}
                    </div>

                    <p className="ptag-email-sidebar !mt-[2px]">
                      {currentUser?.phone}
                    </p>
                  </div>
                </div>
                <div
                  className="logout-sidebar"
                  onClick={() => {
                    setShowLogout(true);
                  }}
                >
                  <LogoutIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        title="Logout Seekho CMS"
        handleConfirmationBtnText="Logout"
        show={showLogout}
        isLoading={isLoading}
        onHide={() => setShowLogout(false)}
        handleConfirmation={() => {
          setIsLoading(true);
          logout(setCurrentUser, setShowLogout, setIsLoading, "");
        }}
      >
        Are you sure you want to logout?
      </CustomModal>
    </div>
  );
};

export default SideNavigation;

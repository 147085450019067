import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  handleAppendPageToUrl,
  handleConvertUTCToIST,
} from "../../utils/utils";
import { apiGateway } from "../../utils/config";
import { Chip, LinearProgress, Pagination } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import IconBreadcrumbs from "../BreadCrumbs/BreadCrumsCustom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import NoDataFound from "../Common/NoDataFound";
import GuitarIllustration from "../Common/GuitarIllustration";

const TrackLogs = ({ match }) => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [trackLogs, setTrackLogs] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const [pageNumber, setPageNumber] = useState(searchParamPage);
  const trackId = match.params?.track_id;
  const tableHeading = [
    "Profile Id",
    "Editor",
    "Action Made",
    "Date",
    "Status",
  ];
  const axiosPrivate = useAxiosPrivate();

  const handleGetTrackLogs = async () => {
    try {
      setIsLoading(true);
      const url = `${apiGateway}/api/v1/cms/track/${trackId}/logs/?page=${searchParamPage}`;
      const response = await axiosPrivate.get(url);
      if (response?.data) {
        setIsLoading(false);
        setTrackLogs(response?.data?.logs);
        setTotalPages(response?.data?.n_pages);
        setNoDataFound(response?.data?.logs?.length === 0 ? true : false);
      }
    } catch (error) {
      setNoDataFound(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetTrackLogs();
  }, [pageNumber]);

  return (
    <div className="h-screen">
      <IconBreadcrumbs
        id="Track Logs"
        endPath={`Track ${trackId}`}
        link={`#/track-edit/${trackId}`}
      />
      <div>
        {isLoading && <LinearProgress color="inherit" className="mt-2" />}
        {noDataFound ? (
          <div className="!flex !justify-center !items-center h-[calc(100vh-160px)]">
            <GuitarIllustration
              className="!w-[250px] !mx-auto"
              displayText="No Logs To Display or Something went wrong"
            />
          </div>
        ) : (
          <div>
            <div className="!relative !overflow-x-auto h-[calc(100vh-140px)]  rounded-md !border border-[#534f8f] bg-white mt-2">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-gray-700 bg-white border-b">
                  <tr>
                    {tableHeading.map((item) => {
                      return (
                        <th
                          scope="col"
                          className="px-6 py-3 !text-[13px] bg-white z-[999] whitespace-nowrap"
                          key={item}
                        >
                          {item}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {trackLogs?.map((item) => {
                    return (
                      <tr
                        className="hover:!bg-[#e0deff] text-black cursor-pointer !font-normal text-[12px]"
                        key={item?.id}
                      >
                        <td scope="row" className="px-6 py-4 whitespace-nowrap">
                          {item?.profile_id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[15px]">
                          {item?.name}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap capitalize">
                          {item?.action}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          {handleConvertUTCToIST(item?.created_on)}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <Chip
                            label={item?.status}
                            variant="filled"
                            color={
                              item?.status === "success" ? "success" : "error"
                            }
                            className="px-2 !w-[85px] capitalize"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Pagination
              count={totalPages}
              page={searchParamPage}
              onChange={(e, val) => {
                setPageNumber(val);
                handleAppendPageToUrl({ history, pageNumber: val });
              }}
              variant="outlined"
              shape="rounded"
              className="!w-fit !mx-auto mt-3"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(TrackLogs);

import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import { Typography } from '@mui/material';

function handleClick(event) {
  event.preventDefault();
}

export default function IconBreadcrumbs({endPath, id, link}) {
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
          color="inherit"
          onClick={() => {
            window.location.href = "#/series"
          }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </Link>
        {id?.length > 0 ? <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}
          color="inherit"
          // href="#/series"
          onClick={() => {
            window.location.href = link
          }}
        >
          {endPath}
        </Link> :
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          {endPath}
        </Typography>}
        {id?.length > 0 && <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          {id}
        </Typography>}
        
      </Breadcrumbs>
    </div>
  );
}
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom';

function SeriesDashboard({match, history}) {
    const seriesId = match?.params?.series_id

    return (
        <iframe
            title="Embedded Website"
            src={`https://analytics.seekho.in/public/dashboards/ONrYiljSLUFka4PBaYlAOEJRUZpB4XZPARqYHKsK?org_slug=default&p_series_id=${seriesId}&refresh=300&fullscreen`}
            width='100%'
            style={{ 
                height: "98vh",
            }}
            frameBorder="0"
        />
    )
}

export default withRouter(SeriesDashboard);
import React from "react";
import TeamListing from "../../components/Team";

const TeamMembersPage = () => {
  return (
    <div>
      <TeamListing />
    </div>
  );
};

export default TeamMembersPage;

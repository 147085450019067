import React from "react";
import "./video-detail.styles.css";
import { Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function VideoDetail({
  video,
  imageUrl,
  id,
  title,
  category,
  handleVideoModal,
  videoUrl,
  series,
  numberOfCharacters = 30,
  playIndex,
  hasQuiz,
  setSelectedVideo,
  updateVideo
}) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {title}
    </Tooltip>
  );
  return (
    <div className="video-detail">
      <img
        alt={title}
        className="video-image"
        src={imageUrl}
        onClick={() => { 
          if(setSelectedVideo)
          setSelectedVideo(video);
          handleVideoModal(videoUrl, title, playIndex)
        }}
      ></img>
      <div className="video-info">
        <div className="video-id">#{id} 
        </div>
        <div className="video-title">{title.substring(0, numberOfCharacters)} </div>
        <div className="video-category">{category}</div>
        {series && <div className="video-series">
          <Link to={`/series/${series.slug}`}>
            {series?.title} 
          </Link>
        </div>}
      </div>
    </div>
  );
}

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CircularProgress } from "@mui/material";

function CustomModal({
  show,
  onHide,
  title,
  key,
  children,
  handleConfirmation,
  isLoading,
  handleConfirmationBtnText = "Yes",
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      key={key}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleConfirmation}
          disabled={isLoading}
          style={{ background: "#555190", borderColor: "#555190" }}
        >
          {isLoading ? (
            <div className="h-[25px]">
              <CircularProgress className="text-white mt-1" size={20} />
              <p className="opacity-0">{handleConfirmationBtnText}</p>
            </div>
          ) : (
            handleConfirmationBtnText
          )}
        </Button>
        <Button
          onClick={onHide}
          className="bg-gray-600 border-transparent hover:bg-gray-600 hover:border-transparent"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;

import React, { useEffect, useRef, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import "./show-details.styles.css";
import placeholder from "../../assests/images/placeholder1.png";
import { Dropdown, Button, Modal } from "react-bootstrap";
import Loader from "../../components/Loader/index";
import { Chip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SeriesList from "../../components/SeriesList/series-list.component";
import {
  faChartLine,
  faEllipsisH,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import IconBreadcrumbs from "../BreadCrumbs/BreadCrumsCustom";
import { apiGateway } from "../../utils/config";
import AlertComponent from "../Alert-Messages/alert-component.component";
import { GET_SHOW_ACTION_V1, SHOW_V1 } from "../../utils/constants";
import { useSelector } from "react-redux";
import { handleFormatNumberOfFollowers } from "../../utils/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CreateShow from "../Create-Show/create-show.component";
import useZustandStore from "../../zustandStore/useZustandStore";

function ShowDetails({ history, match }) {
  var showSlug = match.params.show_slug;
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const axiosPrivate = useAxiosPrivate();
  const [show, setShow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLinkLoading, setIsLinkLoading] = useState(false);
  const [isDialogCreate, setIsDialogCreate] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ error: "", type: "success" });
  const [dialogStatus, setDialogStatus] = useState({ open: false });
  const { globalRoles, internalTeamAccessGlobalState } = useZustandStore();
  const isAdmin = globalRoles?.admin;
  const isSuperAdmin = globalRoles?.superAdmin;
  const isEditor = globalRoles?.editor;
  const isCreator = globalRoles?.creator;
  const hasAccessToActions = internalTeamAccessGlobalState || isCreator;

  useEffect(() => {
    fetchShowData(showSlug);
  }, [showSlug]);

  const fetchShowData = (showSlug) => {
    if (!showSlug || showSlug == "" || showSlug == undefined) {
      return;
    } else {
      setIsLoading((prev) => true);
      let url = `${apiGateway}${SHOW_V1}${showSlug}/`;
      axiosPrivate
        .get(url)
        .then(({ data, status }) => {
          if (
            status !== 200 ||
            (data?.error_message && Object.keys(data?.error_message).length > 0)
          ) {
            setErrorMsg({
              type: "failed",
              error: data?.error_message ?? "Something went wrong",
            });
          } else {
            setShow(data?.show_data);
          }
          setIsLoading((prev) => false);
        })
        .catch(({ response }) => {
          if (response?.status == 404) {
            setErrorMsg({ type: "failed", error: "Url not found" });
          } else {
            setErrorMsg({
              type: "failed",
              error: response?.data
                ? response?.data?.error_message
                : "Something went wrong",
            });
          }
          setIsLoading((prev) => false);
        });
    }
  };

  const copyShortLink = () => {};

  const toggleStatus = (show_slug, status) => {
    let formData = new FormData();
    formData.append("show_state", status);
    let url = `${apiGateway}${GET_SHOW_ACTION_V1}/${show_slug}/update-state/`;
    // setIsLoading(true);
    axiosPrivate
      .post(url, formData)
      .then(({ data, status }) => {
        if (status == false) {
          setErrorMsg({
            type: "failed",
            error: data?.error_message ?? "Something went wrong",
          });
        } else {
          // setIsLoading(false);
          fetchShowData(showSlug);
          setErrorMsg({ type: "success", error: "Track is status changed" });
        }
      })
      .catch(({ response }) => {
        console.log(response);
        if (response?.status == 404) {
          setErrorMsg({ type: "failed", error: "Url not found" });
        } else {
          setErrorMsg({
            type: "error",
            error: response?.data?.error_message || response?.data?.message,
          });
        }
        // setIsLoading(false);
      });
    setDialogStatus({ open: false });
  };

  const toggleCreate = (e, show_created) => {
    if (isDialogCreate && show_created) {
      fetchShowData(showSlug);
    }
    setIsDialogCreate((prev) => !prev);
  };

  return (
    <>
      <div>
        <AlertComponent
          message={errorMsg?.error}
          type={errorMsg?.type}
          setAlertNotification={() =>
            setErrorMsg({ error: "", type: "failed" })
          }
        />
        <IconBreadcrumbs endPath={"Shows"} id={showSlug} link={"#/shows"} />
        {!isLoading ? (
          show ? (
            <div className="show-overall-details">
              <div className="series-info mt-2">
                <div className="show-basic-info">
                  <div className="img-hover-play-show">
                    <img
                      className="show-image"
                      src={show?.original_image ? show?.image : placeholder}
                      alt={show?.title}
                    />
                  </div>
                  <div className="show-status-info">
                    <div className="series-id shows-data-item-flex mb-1">
                      <b>#{show?.id} </b>
                      <span
                        className="language_chip"
                        style={{ border: "2px solid orange", color: "orange" }}
                      >
                        {"Hindi"}
                      </span>
                      <Button
                        className="copy-short-link-btn show-bn"
                        onClick={() => copyShortLink(showSlug)}
                      >
                        {isLinkLoading
                          ? "Generating short link"
                          : "Copy Short link"}
                      </Button>
                      {internalTeamAccessGlobalState && (
                        <Button
                          className="text-[12px] !border-2 !px-2 !py-0 !border-blue-500 bg-blue-50 text-blue-500 flex gap-x-2 items-center rounded-xl hover:bg-blue-50 hover:text-blue-500"
                          onClick={() =>
                            (window.location.href = `#/show-dashboard/${show?.id}`)
                          }
                        >
                          <span className="mt-[2px]">Performance</span>
                          <FontAwesomeIcon icon={faChartLine} />
                        </Button>
                      )}

                      {show?.topic_sheet_link ? (
                        <a
                          href={show?.topic_sheet_link}
                          target="_blank"
                          rel="noreferrer"
                          className="text-[13px] underline font-normal ml-1"
                        >
                          Topic Tracker
                        </a>
                      ) : (
                        <p className="text-[12px]">No Topic Tracker Assigned</p>
                      )}
                    </div>
                    <div className="series-title mt-3">
                      <Chip
                        label={show?.status.toUpperCase()}
                        className="!px-4 !py-0 rounded-full !bg-[#74c395] text-white mr-2 !text-[11px] !font-normal"
                      />
                      {show?.title} --{" "}
                      <span className="default-follower">
                        {show?.n_followers &&
                          handleFormatNumberOfFollowers({
                            numberOf: show?.n_followers,
                          })}{" "}
                        Followers
                      </span>
                    </div>

                    <div className="mt-3 flex gap-x-4 w-fit">
                      <Link to={`/creators/${show?.creator?.id}`}>
                        <FontAwesomeIcon icon={faUser} fontSize={12} />{" "}
                        {show?.creator?.name}
                      </Link>

                      {show?.category && (
                        <span className="series-category">
                          {show?.category?.title}
                        </span>
                      )}
                    </div>
                    <div className="text-[13px] mt-3 text-gray-600 w-[65%]">
                      {show?.description}
                    </div>
                  </div>
                </div>

                {hasAccessToActions && (
                  <div className="series-basic-action">
                    <div className="edit-delete">
                      <div className="changes">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="secondary"
                            className="dropdown-action"
                          >
                            <FontAwesomeIcon
                              icon={faEllipsisH}
                              className="rejection-logs mr-3 ml-0"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className="action-menu-item"
                              onClick={() => toggleCreate()}
                            >
                              Edit Show
                            </Dropdown.Item>
                            {(isAdmin ||
                              isSuperAdmin ||
                              (isEditor && show?.status === "live")) && (
                              <Dropdown.Item
                                className="action-menu-item"
                                onClick={() => {
                                  setDialogStatus({
                                    open: true,
                                    type:
                                      show?.status == "draft"
                                        ? "live"
                                        : "draft",
                                    msg:
                                      show?.status == "draft"
                                        ? "Make this show live"
                                        : "Move this show to draft",
                                  });
                                }}
                              >
                                {show?.status == "draft"
                                  ? "Make Live"
                                  : "Move to Draft"}
                              </Dropdown.Item>
                            )}
                            {show?.status === "draft" && (
                              <Dropdown.Item
                                className="action-menu-item"
                                onClick={() => {
                                  setDialogStatus({
                                    open: true,
                                    type: "deleted",
                                    msg: "Delete this show",
                                  });
                                }}
                              >
                                Delete Show
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="series-section">
                <SeriesList isOnShowPage={true} showDetail={show}></SeriesList>
              </div>
            </div>
          ) : (
            <div className="center-loader-screen">
              Sorry we failed to load the data
            </div>
          )
        ) : (
          <div className="center-loader-screen">
            <Loader />
          </div>
        )}
      </div>
      <Modal show={dialogStatus?.open} centered>
        <Modal.Header
          closeButton
          onHide={() => {
            setDialogStatus({ open: false });
          }}
        >
          {show?.title}
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>{dialogStatus.msg}</b>
            <br />
            Want to proceed with changes?
          </p>
          <div>
            <Button
              className="button-custom"
              disabled={isLoading}
              onClick={() => {
                toggleStatus(show?.slug, dialogStatus?.type);
              }}
            >
              {isLoading ? "Loading..." : "Update"}
            </Button>
            <Button
              className="button-custom-cancel"
              onClick={() => {
                setDialogStatus({ open: false });
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* CREATE FORM MODAL */}
      <Modal show={isDialogCreate} centered>
        <Modal.Header closeButton onHide={toggleCreate}>
          <div className="modal-video-title">Update Show</div>
        </Modal.Header>
        <Modal.Body>
          <CreateShow toggleCreate={toggleCreate} show_data={show} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default withRouter(ShowDetails);
